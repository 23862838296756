import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { getSelectorsByUserAgent } from 'react-device-detect';

import rootReducer from '../reducers';

import {
  loadState,
  saveState,
  clearSavedStoryFiltersStates,
} from '../services/localStorage';
import {
  filterByTimePeriod,
  filterByCategories,
  toggleConflictsFlag,
  loadFilters,
  filterBySelectedSources,
  filterByPremiumContent,
  saveTranslationSupportedLanguages,
  showTranslation,
} from '../actions/storyFilters.action';
import { setActiveHref } from '../actions/leftBar.actions';
import { setOtherDefaultProps, setPageSizes } from '../actions/common.actions';

import { GOLD, GUEST } from '../data/permissions';
import { CONTENT_SOURCE_FILTER, CUSTOM_TIME_FILTER } from '../data/features';
import { RAILS_ENV } from '../data/environment';

import { initialState } from '../data/filters/storyFilters';

import { getUserToken } from '../helpers/getUserToken';
import ExternalSharingStore from './ExternalSharingStore';

let store;
export default ({
  user,
  history,
  location,
  match,
  userAgent,
  ...props
}) => {
  if (store) return store;

  const presistedState = loadState();
  let initialChecksMade = false;

  const composeEnhancers = (
    RAILS_ENV === 'development'
      && typeof window === 'object'
      && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ) : (
        compose
      )
  );

  store = createStore(
    rootReducer,
    {
      ...presistedState,
      userPreferencesReducer: {
        preferences: {
          active_watchlist_id: null,
        },
        subscriptionTopics: [],
        menuOpened: false,
        curationMode: false,
        user,
        loading: false,
        loadingUserInfo: true,
      },
    },
    composeEnhancers(applyMiddleware(thunkMiddleware)),
  );

  store.dispatch(loadFilters(store.getState().storyFilters));

  if (typeof window === 'object') {
    const onResize = () => {
      store.dispatch(setPageSizes({
        width: window.innerWidth,
        height: window.innerHeight,
      }));
    };
    window.addEventListener('resize', onResize, { passive: true });
    onResize();
  }

  store.subscribe(() => {
    const { storyFilters, subscriptions } = store.getState();
    const { dispatch } = store;
    ExternalSharingStore.updateState(store.getState());

    if (!getUserToken()) {
      saveState({ storyFilters });
    } else {
      clearSavedStoryFiltersStates();
      // TODO this should be moved to database
      saveState({
        storyFilters:
        {
          ...initialState,
          required_source: {
            registrationRequired: Boolean(storyFilters?.required_source?.registrationRequired),
            paywall: Boolean(storyFilters?.required_source?.paywall),
          },
          isPremiumLabelsEnabled: Boolean(storyFilters?.isPremiumLabelsEnabled),
        },
      });
    }

    if (
      !storyFilters.conflictsChecked
      && subscriptions.permissionsInitialized
      && storyFilters.filtersInitialized
    ) {
      const { permissions } = subscriptions;
      let conflictFound = false;

      if (permissions.translations) {
        // get languages supported for Translations
        dispatch(saveTranslationSupportedLanguages(getUserToken()));
      }
      if (storyFilters.translate_all && !permissions.access_levels.includes(GOLD)) {
        conflictFound = true;
        if (!initialChecksMade) {
          dispatch(showTranslation(false, getUserToken()));
        }
      }

      if (
        !permissions.allowed_search_periods.includes(storyFilters.time_filter)
        || (!permissions.features.includes(CUSTOM_TIME_FILTER)
          && (storyFilters.selected_time_filter === 'custom' || storyFilters.selected_time_filter === 'time_period'))
      ) {
        conflictFound = true;

        if (!initialChecksMade) {
          dispatch(filterByTimePeriod({
            selected_time_filter: 'predefined',
            time_filter: permissions.search_period,
          }, getUserToken()));
        }
      }

      if (permissions.access_levels.includes(GUEST) && storyFilters.categories.match(/[cie]/)) {
        conflictFound = true;
        if (!initialChecksMade || storyFilters.isOnboardingProcess) {
          const categories = storyFilters.categories.split(',').filter((cat) => !['c', 'i', 'e'].includes(cat)).join(',');
          dispatch(filterByCategories(categories));
        }
      }

      if (
        !permissions.features.includes(CONTENT_SOURCE_FILTER)
        && (storyFilters.domains_filtering_mode === 'whitelist' || storyFilters.domains_filtering_mode === 'blacklist')
      ) {
        conflictFound = true;
        if (!initialChecksMade) {
          dispatch(filterBySelectedSources({
            domains_filtering_mode: 'all',
          }));
        }
      }

      if (storyFilters.premium_content_only && !permissions.access_levels.includes(GOLD)) {
        conflictFound = true;

        if (!initialChecksMade) {
          dispatch(filterByPremiumContent(false, getUserToken()));
        }
      }

      initialChecksMade = true;

      if (!conflictFound) dispatch(toggleConflictsFlag());
    }
  });

  const currentState = store.getState();
  if (
    props?.href
    && !currentState.leftBar.href
    && typeof window === 'object'
  ) {
    store.dispatch(setActiveHref(props.href));
  }
  if (!currentState.common.serverProps.initialized) {
    store.dispatch(setOtherDefaultProps(props));
    if (userAgent && currentState.common.width === 0) {
      const { isDesktop } = getSelectorsByUserAgent(userAgent);

      store.dispatch(setPageSizes({
        width: isDesktop ? 1440 : 320,
        height: isDesktop ? 1080 : 844,
      }));
    }
  }
  return store;
};
