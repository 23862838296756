import axios from 'axios';
import isEqual from 'lodash-es/isEqual';

import {
  RBIInternalLoad,
  storiesLoadAPIData,
} from '../services/dbServices/searchbarDbServices';
import SearchSvc from '../services/SearchSvc';
import dbTabsDataParser from '../helpers/dbTabsDataParser';
import removeDuplicates from '../helpers/removeDuplicates';
import { changeSearchbarDataFields, buildAdvancedSearchQuery, changeSearchbarTopicsDataFields } from '../helpers/searchbarHelpers';
import { ADVANCED_SEARCH } from '../data/searchbar/types';
import { STORIES_LIMIT } from '../data/filters/storyFilters';
import TopicsSvc from '../services/TopicsSvc';
import InsiderSvc from '../services/dbServices/InsiderSvc';
import StakeholdersSvc from '../services/dbServices/StakeholdersSvc';

export const TOGGLE_HEADER_SEARCHBAR_STATE = 'TOGGLE_HEADER_SEARCHBAR_STATE';
export const SELECT_USER_SEARCH_QUERY = 'SELECT_USER_SEARCH_QUERY';
export const TOGGLE_ONBOARDING_MODAL = 'TOGGLE_ONBOARDING_MODAL';
export const TOGGLE_SEARCHBAR_STATE = 'TOGGLE_SEARCHBAR_STATE';
export const FOCUS_SEARCHBAR = 'FOCUS_SEARCHBAR';
export const BLUR_SEARCHBAR = 'BLUR_SEARCHBAR';
export const ADD_SEARCH_ITEM = 'ADD_SEARCH_ITEM';
export const REMOVE_SEARCH_ITEM = 'REMOVE_SEARCH_ITEM';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const ADD_SINGLE_SEARCH_ITEM = 'ADD_SINGLE_SEARCH_ITEM';
export const BACKSPACE_REMOVE_SEARCH_ITEM = 'BACKSPACE_REMOVE_SEARCH_ITEM';
export const INIT_TABS = 'INIT_TABS';
export const LOAD_TAB_INFO = 'LOAD_TAB_INFO';
export const RESET_TABS_DETAILS = 'RESET_TABS_DETAILS';
export const REMOVE_SEARCH_RESULTS = 'REMOVE_SEARCH_RESULTS';
export const ADD_KEYWORD = 'ADD_KEYWORD';
export const LOAD_STORIES = 'LOAD_STORIES';
export const ADD_NEW_WATCHLIST_ASSETS = 'ADD_NEW_WATCHLIST_ASSETS';
export const REMOVE_WL_ASSET = 'REMOVE_WL_ASSET';
export const RESET_ALL = 'RESET_ALL';
export const SET_QUERY = 'SET_QUERY';
export const CHANGE_SEARCH_STATUS = 'CHANGE_SEARCH_STATUS';
export const CHANGE_INTERNAL_SEARCH_STATUS = 'CHANGE_INTERNAL_SEARCH_STATUS';
export const START_LOAD_TAB_TOPICS = 'START_LOAD_TAB_TOPICS';
export const SUCCESS_LOAD_TAB_TOPICS = 'SUCCESS_LOAD_TAB_TOPICS';
export const START_ON_USER_QUERY_LOAD = 'START_ON_USER_QUERY_LOAD';
export const END_ON_USER_QUERY_LOAD = 'END_ON_USER_QUERY_LOAD';
export const HIDE_STORY = 'HIDE_STORY';
export const SET_RBI_INTERNAL = 'SET_RBI_INTERNAL';
export const TOGGLE_SEARCH_STATE = 'TOGGLE_SEARCH_STATE';
export const FALSE_SELECTED_SEARCHBAR_STATE = 'FALSE_SELECTED_SEARCHBAR_STATE';
export const SET_SEARCH_SUMMARIES_REQUEST_METHOD = 'SET_SEARCH_SUMMARIES_REQUEST_METHOD';
export const SET_SEARCHBAR_TYPE = 'SET_SEARCHBAR_TYPE';
export const RESTORE_SEARCHBAR_ITEMS = 'RESTORE_SEARCHBAR_ITEMS';
export const UPDATE_STORIES_INFO = 'UPDATE_STORIES_INFO';
export const UPDATE_STORIES_BOOKMARK = 'UPDATE_STORIES_BOOKMARK';
export const TOOGLE_MANAGE_WATCHLISTS_CREATE_SEARCHBAR = 'TOOGLE_MANAGE_WATCHLISTS_CREATE_SEARCHBAR';
export const TOOGLE_MANAGE_WATCHLISTS_HEADER_SEARCHBAR = 'TOOGLE_MANAGE_WATCHLISTS_HEADER_SEARCHBAR';
export const TOOGLE_PRELOADER_ENTITY = 'TOOGLE_PRELOADER_ENTITY';
export const SET_FIRST_SEARCH_RESULT = 'SET_FIRST_SEARCH_RESULT';
export const SEARCH_RESULT_NEW_PRIVATE_COMPANIES_START = 'SEARCH_RESULT_NEW_PRIVATE_COMPANIES_START';
export const SEARCH_RESULT_NEW_PRIVATE_COMPANIES_END = 'SEARCH_RESULT_NEW_PRIVATE_COMPANIES_END';
export const SEARCH_RESULT_NEW_INVESTORS_START = 'SEARCH_RESULT_NEW_INVESTORS_START';
export const SEARCH_RESULT_NEW_INVESTORS_END = 'SEARCH_RESULT_NEW_INVESTORS_END';

export const toggleHeaderSearchbarState = (searchbarType) => ({
  type: TOGGLE_HEADER_SEARCHBAR_STATE,
  payload: searchbarType,
});

export const toggleSearchState = (state = null) => ({
  type: TOGGLE_SEARCH_STATE,
  payload: state,
});

export const toggleSearchbar = (searchbarType) => (dispatch) => {
  dispatch(toggleHeaderSearchbarState(searchbarType));
};

export const falseSearchbarState = (searchbarType) => ({
  type: FALSE_SELECTED_SEARCHBAR_STATE,
  payload: searchbarType,
});

export const selectUserSearchQuery = (query) => ({
  type: SELECT_USER_SEARCH_QUERY,
  payload: query,
});

export const toggleSearchbarState = () => ({
  type: TOGGLE_SEARCHBAR_STATE,
});

export const focusSearchbar = (isFocus, watchlist) => ({
  type: FOCUS_SEARCHBAR,
  payload: isFocus && watchlist,
});

export const blurSearchbar = (isBlur) => ({
  type: BLUR_SEARCHBAR,
  payload: isBlur,
});

export const toogleOnboardingModal = () => ({
  type: TOGGLE_ONBOARDING_MODAL,
});

export const toogleManageWatchlistsCreateSearchbar = (isOpen) => ({
  type: TOOGLE_MANAGE_WATCHLISTS_CREATE_SEARCHBAR,
  payload: isOpen,
});

export const toogleManageWatchlistsHeaderSearchbar = (isOpen) => ({
  type: TOOGLE_MANAGE_WATCHLISTS_HEADER_SEARCHBAR,
  payload: isOpen,
});

export const startLoadUserQuery = () => ({
  type: START_ON_USER_QUERY_LOAD,
});

export const endLoadUserQuery = () => ({
  type: END_ON_USER_QUERY_LOAD,
});

export const successLoadTabTopics = () => ({
  type: SUCCESS_LOAD_TAB_TOPICS,
});

export const startLoadTabTopics = () => ({
  type: START_LOAD_TAB_TOPICS,
});

export const removeNewWatchlistAsset = (id, index) => ({
  type: REMOVE_WL_ASSET,
  payload: {
    id,
    index,
  },
});

export const addNewWatchlistAssets = (wlAssetItem) => ({
  type: ADD_NEW_WATCHLIST_ASSETS,
  payload: wlAssetItem instanceof Object ? {
    ...wlAssetItem,
    itemType: wlAssetItem.itemType || 'ENTITY',
  } : wlAssetItem,
});

export const removeSearchQueryItem = (id, index) => ({
  type: REMOVE_SEARCH_ITEM,
  payload: {
    id,
    index,
  },
});

export const addSearchItem = (itemName) => ({
  type: ADD_SEARCH_ITEM,
  payload: itemName instanceof Object ? {
    ...itemName,
    itemType: itemName.itemType || 'ENTITY',
  } : itemName,
});

export const setActiveTab = (tabName) => ({
  type: SET_ACTIVE_TAB,
  payload: tabName,
});

export const addSingleSearchItem = (singleItem) => ({
  type: ADD_SINGLE_SEARCH_ITEM,
  payload: singleItem instanceof Object ? {
    ...singleItem,
    itemType: singleItem.itemType || 'ENTITY',
  } : singleItem,
});

export const backspaceSearchItemRemove = () => ({
  type: BACKSPACE_REMOVE_SEARCH_ITEM,
});

export const initTabs = (tabs) => ({
  type: INIT_TABS,
  payload: tabs,
});

export const resetTabsDetails = () => ({
  type: RESET_TABS_DETAILS,
});

export const removeSearchbarResults = () => ({
  type: REMOVE_SEARCH_RESULTS,
});

export const addKeyword = (keyword) => ({
  type: ADD_KEYWORD,
  payload: keyword,
});

export const loadStories = (data) => ({
  type: LOAD_STORIES,
  payload: data,
});

// pushing tab's items to redux store
export const loadTabInfo = (tabData) => ({
  type: LOAD_TAB_INFO,
  payload: tabData,
});

export const resetAllSearch = () => ({
  type: RESET_ALL,
});

// action for setting query
export const setQuery = (query) => ({
  type: SET_QUERY,
  payload: query,
});

export const setSearchSummariesRequestMethod = (method) => ({
  type: SET_SEARCH_SUMMARIES_REQUEST_METHOD,
  payload: method,
});

// actions for RBI search
export const setRBIInternalData = (stories) => ({
  type: SET_RBI_INTERNAL,
  payload: stories,
});

export const setSearchbarType = (type) => ({
  type: SET_SEARCHBAR_TYPE,
  payload: type,
});

export const restoreSearchbarItems = (state) => ({
  type: RESTORE_SEARCHBAR_ITEMS,
  payload: state,
});

export const removeSearchItem = ({
  id,
  index,
  watchlistItems,
}) => (dispatch) => {
  if (watchlistItems) {
    dispatch(removeNewWatchlistAsset(id, index));
  } else {
    dispatch(removeSearchQueryItem(id, index));
  }
};

let argsCache = [];
// simple search action for stories
export const searchStories = (args) => (
  async (dispatch, getState) => {
    const {
      query: basicSearchQuery,
      params,
      nextPage = false,
      client = '',
      earliestSearchDate,
    } = args;

    let loadEndpoint;
    let loadAction;
    let loadingActionType;
    let currentStories;
    let query = basicSearchQuery;
    const {
      searchReducers: {
        stories,
        internalStories,
        activeSearchType,
        searchItems,
        advancedQuerySearchItems,
      },
    } = getState();

    switch (client) {
      case 'RBI':
        loadEndpoint = RBIInternalLoad;
        loadAction = setRBIInternalData;
        loadingActionType = CHANGE_INTERNAL_SEARCH_STATUS;
        currentStories = internalStories;
        break;
      default:
        loadEndpoint = storiesLoadAPIData;
        loadAction = loadStories;
        loadingActionType = CHANGE_SEARCH_STATUS;
        currentStories = stories;
        break;
    }

    SearchSvc.addSearchHistory({
      activeSearchType,
      item: activeSearchType === ADVANCED_SEARCH ? advancedQuerySearchItems : searchItems,
    });

    if (activeSearchType === ADVANCED_SEARCH) {
      query = buildAdvancedSearchQuery(advancedQuerySearchItems);
    }

    if (query && !isEqual(args, argsCache)) {
      argsCache = args;
      dispatch({
        type: loadingActionType,
        payload: true,
      });

      if (!nextPage) {
        dispatch(
          setSearchSummariesRequestMethod(
            (limit = 2, options = {}) => (
              loadEndpoint(query, {
                ...params,
                only_summaries: true,
                number_of_summaries: limit,
                ...options,
              }, earliestSearchDate)
            ),
          ),
        );
      }

      const {
        stories,
        nextPageToken,
        cancelled,
      } = await loadEndpoint(query, params, earliestSearchDate);
      dispatch(
        loadAction({
          stories: (
            nextPage ? [
              ...currentStories,
              ...stories,
            ] : (
              stories
            )
          ),
          nextPageToken,
        }),
      );

      dispatch({
        type: loadingActionType,
        payload: false,
      });
      return { cancelled };
    }

    return {};
  }
);

// action to save the first result from the 'top results' when a user query is made
export const setFirstSearchResult = (item) => ({
  type: SET_FIRST_SEARCH_RESULT,
  payload: item,
});

export const setNewCompaniesSearchStart = () => ({
  type: SEARCH_RESULT_NEW_PRIVATE_COMPANIES_START,
});

export const setNewCompaniesSearchEnd = (item) => ({
  type: SEARCH_RESULT_NEW_PRIVATE_COMPANIES_END,
  payload: item,
});

export const setNewInvestorsSearchStart = () => ({
  type: SEARCH_RESULT_NEW_INVESTORS_START,
});

export const setNewInvestorsSearchEnd = (item) => ({
  type: SEARCH_RESULT_NEW_INVESTORS_END,
  payload: item,
});

let cancelInvestorsSearchReqObj;
export const getInvestorsSearchData = (extraParams = {}) => (
  async (dispatch) => {
    const { } = extraParams;

    if (cancelInvestorsSearchReqObj) cancelInvestorsSearchReqObj.cancel();
    const { CancelToken } = axios;
    cancelInvestorsSearchReqObj = CancelToken.source();

    dispatch(setNewInvestorsSearchStart());
    const data = await StakeholdersSvc.searchStakeholders({
      ...extraParams, cancelToken: cancelInvestorsSearchReqObj.token,
    });

    if (data?.cancelled) return;

    if (!data || data.error) {
      dispatch(setNewInvestorsSearchEnd([]));
      return;
    }

    const parsedData = removeDuplicates([
      ...(data.fuzzy || []),
      ...(data.regular || []),
    ], 'slug');

    dispatch(setNewInvestorsSearchEnd(parsedData));
  }
);

let cancelPrivateCompaniesSearchReqObj;
export const getPrivateCompaniesData = (extraParams = {}) => (
  async (dispatch) => {
    const { } = extraParams;

    if (cancelPrivateCompaniesSearchReqObj) cancelPrivateCompaniesSearchReqObj.cancel();
    const { CancelToken } = axios;
    cancelPrivateCompaniesSearchReqObj = CancelToken.source();

    dispatch(setNewCompaniesSearchStart());
    const data = await InsiderSvc.getCompanySearch({
      ...extraParams, cancelToken: cancelPrivateCompaniesSearchReqObj.token,
    });

    if (data?.cancelled) return;

    if (!data || data.error) {
      dispatch(setNewCompaniesSearchEnd([]));
      return;
    }

    const parsedData = removeDuplicates([
      ...(data.fuzzy || []),
      ...(data.regular || []),
    ], 'companyNumber');

    dispatch(setNewCompaniesSearchEnd(parsedData));
  }
);

// action for load searchbar topics
let cancelSearchReqObj;
export const loadSearchTabInfo = ({
  tab,
  searchQuery,
  updateTab,
  locationsOnly,
  isWatchlist,
}) => (
  async (dispatch) => {
    // here used axios cancel methods to cancel requests if user sends new one
    if (cancelSearchReqObj) cancelSearchReqObj.cancel();
    const { CancelToken } = axios;
    cancelSearchReqObj = CancelToken.source();

    // This is necessary to prevent the loss of other tabs when searching within 'Topic Classes' tab
    const isTopResultsOrTopicClasses = tab === 'Top Results' || tab === 'Topic Classes';

    // check here whether to load topics on user query or default topics
    const params = {
      subsection: isTopResultsOrTopicClasses ? 'top_results' : tab,
      locations_only: locationsOnly,
      limit: tab === isTopResultsOrTopicClasses ? STORIES_LIMIT : 60,
      fuzzy_limit: tab === isTopResultsOrTopicClasses ? 5 : 20,
      popular_first: true,
    };

    if (searchQuery) {
      params.limit = isTopResultsOrTopicClasses ? 5 : 60;
      params.query = searchQuery.trim();
      dispatch(getPrivateCompaniesData({
        company_types: 'private',
        fuzzy_limit: params.fuzzy_limit,
        page_limit: params.limit,
        text: params.query,
        source: 'companieshouse-gb',
      }));
      if (!isWatchlist) {
        dispatch(getInvestorsSearchData({
          name: params.query,
          fuzzy_limit: params.fuzzy_limit,
          page_limit: params.limit,
        }));
      }
    }

    let data;
    if (tab === 'Sectors') {
      const levelsReq = ['Level 1', 'Level 2', 'Level 3', 'Level 4']
        .map((levelKey) => (
          SearchSvc.getSearchMetadataItems({
            ...params,
            subsection: levelKey,
            cancelToken: cancelSearchReqObj.token,
          })
        ));
      const levelsPromise = await Promise.all(levelsReq);

      const levelsData = {
        fuzzy: {},
        regular: {},
      };
      for (let i = 0; i < levelsPromise.length; i += 1) {
        const currentLevel = levelsPromise[i];
        if (!currentLevel.cancelled && !currentLevel.error) {
          levelsData.fuzzy = {
            ...levelsData.fuzzy,
            ...currentLevel.data?.fuzzy,
          };
          levelsData.regular = {
            ...levelsData.regular,
            ...currentLevel.data?.regular,
          };
        }
      }
      data = { data: levelsData };
    } else if (tab === 'Investors') {
      data = {
        data: {},
      };
    } else {
      data = await SearchSvc.getSearchMetadataItems({
        ...params, cancelToken: cancelSearchReqObj.token,
      });
    }

    if (data.cancelled || data.error) return;

    if (tab === 'Top Results') dispatch(setActiveTab('Top Results'));

    const parsedData = dbTabsDataParser(data);

    if ((isTopResultsOrTopicClasses || tab === 'Private Companies and Brands')
      && !parsedData['Private Companies and Brands']) {
      parsedData['Private Companies and Brands'] = [];
    }

    if (!isWatchlist && searchQuery && (isTopResultsOrTopicClasses || tab === 'Investors')
      && !parsedData.Investors) {
      parsedData.Investors = [];
    }

    const tabData = changeSearchbarDataFields(parsedData);

    if (!isWatchlist && searchQuery && isTopResultsOrTopicClasses) {
      const foundInvestor = tabData.findIndex((f) => f.title === 'Investors');
      const currentInvestor = tabData[foundInvestor];
      tabData.splice(foundInvestor, 1);
      tabData.splice(3, 0, currentInvestor);
    }

    if (searchQuery) {
      dispatch(setFirstSearchResult(tabData[0]?.data[0]));
    }

    dispatch(loadTabInfo(tabData));

    if (tab === 'Topic Classes') {
      const topicClasses = await TopicsSvc.getTopicClasses();

      const filteredTopics = searchQuery
        ? topicClasses.filter((topic) => topic.title
          .toLowerCase()
          .includes(searchQuery.trim().toLowerCase()))
        : topicClasses;

      dispatch(setActiveTab('Topic Classes'));
      const topicsTabData = changeSearchbarTopicsDataFields(filteredTopics);
      dispatch(loadTabInfo([topicsTabData]));
    }

    // update menu tabs that comes with info when user some query
    if (updateTab) {
      let searchTabs = tabData.map(({ title }) => title);
      const findLevelsIndex = searchTabs.findIndex((f) => f.toLowerCase().includes('level'));
      if (findLevelsIndex > -1) {
        if (findLevelsIndex < 5) {
          searchTabs[findLevelsIndex] = 'Sectors';
        } else {
          searchTabs.splice(5, 0, 'Sectors');
        }
        searchTabs = searchTabs.filter((f) => !f.toLowerCase().includes('level'));
      }
      dispatch(initTabs(searchTabs));
    }

    dispatch(endLoadUserQuery());
  }
);

export const hideStory = (id) => ({
  type: HIDE_STORY,
  payload: id,
});

export const updateStoriesData = (data) => ({
  type: UPDATE_STORIES_INFO,
  payload: data,
});

export const updateSearchStoriesInfo = (data) => ((dispatch, getState) => {
  const { id, liked, disliked } = data;
  const {
    searchReducers: { stories },
  } = getState();

  const updatedStories = stories.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        liked,
        disliked,
      };
    }
    return item;
  });
  dispatch(updateStoriesData(updatedStories));
});

export const updateStoriesBookmarkData = (data) => ({
  type: UPDATE_STORIES_BOOKMARK,
  payload: data,
});

export const updateSearchStoriesBookmark = (data) => ((dispatch, getState) => {
  const { id, bookmarked } = data;
  const {
    searchReducers: { stories },
  } = getState();
  const updatedStories = stories.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        bookmarked,
      };
    }
    return item;
  });
  dispatch(updateStoriesBookmarkData(updatedStories));
});

export const searchRBINews = searchStories;

export const togglePreloaderEntity = (status) => ({
  type: TOOGLE_PRELOADER_ENTITY,
  payload: status,
});
